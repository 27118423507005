$height-breakpoint: 800px;
$width-breakpoint: 1150px;

$vars: (
  spacing: 25px,
  bar-height: 50px,
);

$vars-sm: (
  spacing: 15px,
  bar-height: 50px,
);

@mixin var($property, $varName) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName});
}

:export {
  height-breakpoint: $height-breakpoint;
  width-breakpoint: $width-breakpoint;

  @each $key, $value in $vars {
    #{$key}: $value;
  }

  @each $key, $value in $vars-sm {
    #{$key}-sm: $value;
  }
}
