@import '_vars.scss';

.About {
  line-height: 1.4em;

  h1 {
    font-size: 24px;
    line-height: 30px;
  }

  h1,
  h2 {
    margin: 25px 0;
  }

  h1:first-child {
    margin-top: 0;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
  }

  a {
    color: black;
  }

  li {
    margin-bottom: 16px;
    &:empty {
      display: none;
    }
  }

  .donate-container {
    margin: 50px 0;
    text-align: center;
  }
}

@media (max-width: $width-breakpoint) {
  .About .donate-container {
    margin: 20px 0;
  }
}
