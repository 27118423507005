@import '_vars';

.leaflet-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.map-container {
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  display: flex;
  height: 410px;
  max-height: 50vh;
  min-height: 280px;
  overflow: hidden;
  position: relative;
}

.map-container .totals {
  background: rgba(245, 245, 245, 0.67);
  left: 0;
  padding: 15px;
  position: absolute;
  text-align: center;
  top: 0;
  z-index: 1000;

  .dollars {
    display: none;
  }
}

.map-container .totals strong {
  font-size: 24px;
  font-weight: normal;
}

.map-container .stat.days br {
  display: none;
}

.map-container .totals .stat:not(:last-child) {
  border-bottom: 2px solid #969696;
  margin-bottom: 8px;
  padding-bottom: 6px;
}

@media (max-width: $width-breakpoint) {
  .map-container {
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    display: flex;
    max-height: 9999px;
    @include var(padding-bottom, bar-height);
    @include var(padding-top, bar-height);
  }

  .map-container .totals {
    background: none;
    @include var(bottom, bar-height);
    padding: 12px;
    pointer-events: none;
    right: 0;
    @include var(top, bar-height);
  }

  .map-container .totals .stat {
    align-items: center;
    background: rgba(252, 252, 252, 0.67);
    border-bottom: none !important;
    border-radius: 28px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    font-size: 13px;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    pointer-events: all;
    width: 227px;

    &.stat.dollars {
      background: rgb(0, 128, 117);
      bottom: 0;
      color: white;
      font-weight: bold;
      right: 12px;
      line-height: 123%;
      padding: 0 20px;
      position: absolute;
      width: auto;
    }
  }

  .map-container .totals .stat br {
    display: block;
  }

  .map-container .totals .stat.days {
    position: absolute;
    bottom: 0;
    left: 12px;
    display: block;
    width: 130px;
    box-sizing: border-box;
    text-transform: lowercase;
    padding: 5px 0;
  }

  .map-container .totals .stat.days div {
    display: inline;
  }

  .map-container .totals .stat strong {
    font-size: 16px;
  }
}
