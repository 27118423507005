@import '_vars';

.How {
  font-size: 14px;
  @include var(padding-bottom, spacing);

  svg {
    max-width: 100%;
  }

  .bg {
    bottom: 0;
    left: 25px;
    pointer-events: none;
    position: absolute;
    z-index: 8888;
  }

  .slide-browse-app img {
    padding: 0 25px;
  }

  .slide-whatsapp-chat img {
    background: black;
    bottom: 38px;
    left: 81px;
    padding-bottom: 2px;
    position: absolute;
    width: 173px;
    z-index: 8888;
  }

  .graphic {
    margin: 0 auto;
    position: relative;
    width: 335px;
  }

  .carousel {
    .slider {
      position: relative;
    }

    .control-dots {
      bottom: auto;
      margin: 0;
      padding: 0;
      top: 0;

      li {
        background: #008075;
        height: 12px;
        width: 12px;
      }
    }

    .slide {
      background: transparent;
      position: relative;

      p {
        @include var(margin-bottom, spacing);
        margin-top: map-get($vars, spacing) + 14px;
        margin-top: calc(var(--spacing) + 14px);
        padding: 0 25px;
        text-align: left;
      }

      iframe.creative {
        border-radius: 44px;
        bottom: -152px;
        display: block;
        height: 762px;
        left: -6px;
        margin: 0;
        transform: scale(0.5);
        position: absolute;
        width: 348px;
        z-index: 8888;
      }

      iframe.creative.tap {
        bottom: -170px;
        height: 782px;
        left: -16px;
        width: 364px;
      }
    }

    .control-arrow {
      opacity: 1;

      &:before {
        transition: border-color 0.25s ease-in;
      }

      &.control-prev:before {
        border-right-color: #008075;
      }

      &.control-next:before {
        border-left-color: #008075;
      }

      &:hover {
        &.control-prev:before {
          border-right-color: white;
        }

        &.control-next:before {
          border-left-color: white;
        }
      }
    }
  }
}
