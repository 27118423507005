@import '_vars';

.Language {
  ul {
    display: flex;
    font-size: 14px;
    list-style: none;
    margin: 0 5px;
    padding: 0;

    li {
      margin: 0 8px;

      &.active {
        border-bottom: 2px solid white;
      }

      a {
        display: inline-block;
        padding: 3px 0;
      }
    }
  }
}

@media (max-width: $width-breakpoint) {
  .Language {
    .active {
      display: none;
    }
  }
}
