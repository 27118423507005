@import '_vars';

html {
  @each $key, $value in $vars {
    --#{$key}: #{$value};
  }
}

@media (max-height: $height-breakpoint) {
  html {
    @each $key, $value in $vars-sm {
      --#{$key}: #{$value};
    }
  }
}

h3 {
  font-size: 24px;
  line-height: 18px;
}

.footer {
  align-items: center;
  background: #008075;
  box-sizing: border-box;
  color: white;
  display: none;
  font-size: 18px;
  font-weight: bold;
  @include var(height, bar-height);
  padding: 0 12px;
}

.footer h3 {
  font-size: 18px;
  line-height: 30px;
  margin: 0 18px 0 0;
  border-right: 1px solid white;
  padding-right: 18px;
}

.footer ul {
  align-items: center;
  display: flex;
}

.footer li {
  display: flex;
  margin-right: 16px;
}

.footer {
  img {
    max-height: 26px;
  }

  img.donor-logo-pubnative {
    max-height: 38px;
  }

  img.donor-logo-appsflyer {
    max-height: 32px;
  }
}

.content {
  @include var(margin-top, bar-height);
  @include var(padding, spacing);
}

.meta-container {
  display: flex;
  @include var(padding-top, spacing);
}

.meta-container .right-sidebar {
  font-size: 14px;
  width: 335px;
}

.meta-container .right-sidebar-inner {
  background: #f7f7f7;
  border-radius: 5px;
}

.meta-container .left-sidebar {
  color: white;
  font-weight: bold;
  width: 335px;

  li {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
  }

  svg,
  img {
    max-height: 40px;
  }
}

.meta-container .left-sidebar-inner {
  background: #008075;
  @include var(padding, spacing);
}

.meta-container .left-sidebar hr {
  border-color: white;
}

.sidebar h3 {
  margin: 0;
  @include var(margin-bottom, spacing);
  @include var(padding-bottom, spacing);
  text-align: center;
}

.sidebar {
  will-change: min-height;
}

.sidebar__inner {
  overflow-y: auto;
  transform: translate(0, 0); /* For browsers don't support translate3d. */
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}

.sidebar-inner {
  border-radius: 5px;
  position: relative;
}

.meta-container .left-sidebar h3 {
  border-bottom: 1px solid white;
}

.sidebar ul,
.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.meta-container .left-sidebar ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  padding-bottom: map-get($vars, spacing) * 2.8;
  padding-bottom: calc(var(--spacing) * 2.8);

  img,
  svg {
    max-width: 100%;
  }
}

.meta-container .left-sidebar li {
  box-sizing: border-box;
  @include var(margin-bottom, spacing);
  max-width: 50%;
  min-width: 50%;
  padding: 0 10px;
  text-align: center;
  width: 50%;
}

.meta-container .left-sidebar .sidebar-footer {
  border-top: 1px solid white;
  @include var(margin-top, spacing);
  @include var(padding-top, spacing);

  a {
    align-items: center;
    color: white;
    display: flex;
    justify-content: center;
    line-height: 23px;
    text-decoration: none;
  }
}

.meta-container .left-sidebar .sidebar-footer svg {
  font-size: 14px;
  height: 23px;
  margin-left: 16px;
  width: 27px;
}

.meta-container .center-content {
  line-height: 20px;
  padding: 0 map-get($vars, spacing) * 2.3;
  padding: 0 calc(var(--spacing) * 2.3);
  flex: 1;
}

.meta-container .center-content h3 {
  @include var(margin-bottom, spacing);
  @include var(margin-top, spacing);
}

.right-sidebar h3 {
  border-bottom: 1px solid #a2a2a2;
  @include var(padding-top, spacing);
  @include var(margin-left, spacing);
  @include var(margin-right, spacing);
}

.dollars {
  border-bottom: 1px solid white;
  font-size: 18px;
  line-height: 28px;
  @include var(margin-bottom, spacing);
  margin-top: -5px;
  padding-bottom: map-get($vars, spacing) - 5;
  padding-bottom: calc(var(--spacing) - 5px);
  text-align: center;

  .odometer .odometer-inside::before {
    content: '$';
  }
}

a.donate {
  background: #f96424;
  border-radius: 5px;
  color: white !important;
  display: inline-block;
  font-size: 22px;
  font-weight: 600 !important;
  line-height: 36px !important;
  padding: 0 20px;
  text-decoration: none;
}

@media (max-width: $width-breakpoint) {
  .meta-container {
    display: none;
  }

  .map-container {
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    display: flex;
    max-height: 9999px;
    @include var(padding-bottom, bar-height);
    @include var(padding-top, bar-height);
  }

  .content {
    margin-top: 0;
    padding: 0;
  }

  .footer {
    bottom: 0;
    display: flex;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    z-index: 9999;
  }

  .map-container .totals {
    background: none;
    @include var(bottom, bar-height);
    padding: 12px;
    pointer-events: none;
    right: 0;
    @include var(top, bar-height);
  }

  .map-container .totals .stat {
    align-items: center;
    background: #fcfcfc;
    border-bottom: none !important;
    border-radius: 28px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    font-size: 13px;
    height: 48px;
    justify-content: center;
    margin: 0 auto;
    pointer-events: all;
    width: 227px;
  }

  .map-container .totals .stat br {
    display: block;
  }

  .map-container .totals .stat:first-child {
    position: absolute;
    bottom: 0;
    left: 12px;
    display: block;
    width: 130px;
    box-sizing: border-box;
    text-transform: lowercase;
    padding: 5px 0;
  }

  .map-container .totals .stat:first-child div {
    display: inline;
  }

  .map-container .totals .stat strong {
    font-size: 16px;
  }
}
