@import '_vars.scss';

.Header {
  background: #008075;
  box-sizing: border-box;
  font-size: 24px;
  @include var(height, bar-height);
  @include var(padding-left, spacing);
  @include var(padding-right, spacing);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  .burger {
    display: none;
  }

  .bar {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;

    a,
    a:hover {
      color: white;
      text-decoration: none;
    }

    .logo {
      height: 36px;
    }
  }

  .dropdown {
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    left: 0;
    margin: 0;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    @include var(top, bar-height);
    width: 100%;

    > li > a,
    > li > a:hover {
      align-items: center;
      color: black;
      display: flex;
      font-size: 18px;
      line-height: 42px;
      padding: 0 12px;
      text-decoration: none;
    }

    .menu-item-content {
      padding: 0 12px;
    }
  }

  .arrow {
    margin-left: 16px;
    height: 0;
    width: 0;
  }

  .arrow.up {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
  }

  .arrow.down {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }

  .About {
    font-size: 14px;

    h1:first-child {
      display: none;
    }

    h1 {
      font-size: 18px;
    }

    h2 {
      font-size: 16px;
    }
  }

  .How {
    margin: 0 -12px;
    padding-top: 20px;
    position: relative;
  }

  .donate {
    margin-left: 20px;
  }
}

@media (max-width: $width-breakpoint) {
  .Header {
    left: 0;
    padding: 0 12px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;

    .bar {
      justify-content: flex-start;

      > a.donate {
        display: none;
      }

      .burger {
        display: block;
        margin-right: 18px;
      }

      .logo {
        &,
        svg {
          height: 24px;
          width: auto;
        }
      }
    }
  }
}
